import React, { useState, useEffect, useContext, useCallback } from "react";
import { getTransactions, getTransactionById } from "api/transactions";
import { useAuth } from "AuthContext";
import { formatAmount } from "utils";
import countries from "./countries.json";
import transactionsMock from "./transactions_mock.json";

function formatCountry(code) {
  const country = countries.find((c) => c.code === code);
  return country.name || code;
}

export default function formatDate(inputDate) {
  const months = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  // Парсим дату с учетом часового пояса
  const date = new Date(inputDate);

  const day = date.getDate(); // Локальный день
  const month = months[date.getMonth()]; // Локальный месяц
  const hours = date.getHours(); // Локальные часы
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Минуты с ведущим нулем

  return `${day} ${month} в ${hours}:${minutes}`;
}

function remapTransactions(transactions) {
  return transactions
    .filter((transaction) => {
      return (
        transaction.transaction_type === 7 &&
        transaction.metadata &&
        transaction.metadata.foreignAmount &&
        transaction.metadata.emsPaymentInfo &&
        transaction.metadata.riaPaymentInfo &&
        typeof transaction.metadata.emsPaymentInfo.beneficiary === "object"
      );
    })
    .map((transaction) => {
      return {
        ...transaction,
        amount: formatAmount(transaction.amount),
        created_at: formatDate(transaction.created_at),
        metadata: {
          ...transaction.metadata,
          foreignAmount: formatAmount(transaction.metadata.foreignAmount),
          emsPaymentInfo: {
            ...transaction.metadata.emsPaymentInfo,
            payment_country: formatCountry(
              transaction.metadata.emsPaymentInfo.payment_country
            ),
          },
        },
      };
    });
}

const TransactionsContext = React.createContext();

export const TransactionsProvider = ({ children }) => {
  const { token } = useAuth();

  const [transactions, setTransactions] = useState(null);
  const [fetchingTransactions, setFetchingTransactions] = useState(false);
  const [transactionsError, setTransactionsError] = useState([]);

  const [transactionByID, setTransactionByID] = useState(null);
  const [fetchingTransactionByID, setFetchingTransactionByID] = useState(false);
  const [transactionByIDError, setTransactionByIDError] = useState([]);

  const fetchTransactions = async () => {
    try {
      setFetchingTransactions(true);
      const transactionsResponse = await getTransactions(token);
      transactionsResponse &&
        setTransactions(remapTransactions(transactionsResponse));
      setFetchingTransactions(false);
    } catch (e) {
      setTransactionsError({
        title: "Ошибка",
        message: "Не удалось получить список последних транзакций",
      });
    } finally {
      setFetchingTransactions(false);
    }
  };

  const fetchTransactionByID = async (id) => {
    try {
      setFetchingTransactionByID(true);
      const transactionByIDResponse = await getTransactionById(token, id);
      transactionByIDResponse && setTransactionByID(transactionByIDResponse);
      setFetchingTransactionByID(false);
    } catch (e) {
      setTransactionByIDError({
        title: "Ошибка",
        message: "Не удалось получить данные о транзакции",
      });
    } finally {
      setFetchingTransactionByID(false);
    }
  };

  return (
    <TransactionsContext.Provider
      value={{
        fetchTransactions,
        transactions,
        setTransactions,
        fetchingTransactions,
        transactionsError,

        fetchTransactionByID,
        transactionByID,
        setTransactionByID,
        fetchingTransactionByID,
        transactionByIDError,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

export const useTransactions = () => useContext(TransactionsContext);
