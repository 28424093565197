import React, { useState, useEffect, useContext } from "react";
import {
  getCountries,
  getPartners,
  checkRequest,
  sendRequest,
  cancelTransfer,
} from "api/ria";
import { useAuth } from "AuthContext";

const mapRequestBody = (form) => ({
  amount: form.amount * 100,
  currency: form.currency.code,
  payment_country: form.country.code,
  beneficiary: {
    personal_information: {
      bene_first_name: form.beneficiaryFirstName,
      bene_last_name: form.beneficiaryLastName,
      bene_date_of_birth: form.beneficiaryDateOfBirth,
      bene_nationality: form.beneficiaryCountry.code,
      bene_country_of_birth: form.beneficiaryCountry.code,
    },
    residence: {
      bene_address: form.beneficiaryAddress,
      bene_city: form.beneficiaryCity,
      bene_state: form.beneficiaryState,
      bene_zip_code: form.beneficiaryZipCode,
      bene_country: form.beneficiaryCountry.code,
    },
    identity_document: {
      bene_id_no: form.beneficiaryIDNumber,
      bene_id_type: "Passport",
    },
  },
  payout_partner: {
    paying_corresp_id: form.partner.partner_id,
  },
});

const handleError = (error, setError) => {
  const {
    name = false,
    description = false,
    error: errorMessage = false,
    error_message = false,
  } = error.response?.data || {};

  setError({
    title: name === "RIA_ERROR" ? "Ошибка от провайдера" : "Ошибка",
    message:
      errorMessage || description || error_message || "Неизвестная ошибка",
  });
};

const RiaContext = React.createContext();
export const RiaProvider = ({ children }) => {
  const { token } = useAuth();
  const [countries, setCountries] = useState(null);
  const [fetchingCountries, setFetchingCountries] = useState(false);
  const [countriesError, setCountriesError] = useState(false);

  const [partners, setPartners] = useState(null);
  const [fetchingPartners, setFetchingPartners] = useState(false);
  const [partnersError, setPartnersError] = useState(false);

  const [fetchingCheckRequest, setFetchingCheckRequest] = useState(false);
  const [checkRequestResponse, setCheckRequestResponse] = useState(false);
  const [checkRequestError, setCheckRequestError] = useState(false);

  const [fetchingSendRequest, setFetchingSendRequest] = useState(false);
  const [sendRequestResponse, setSendRequestResponse] = useState(false);
  const [sendRequestError, setSendRequestError] = useState(false);

  const [fetchingCancelRequest, setFetchingCancelRequest] = useState(false);
  const [cancelRequestResponse, setCancelRequestResponse] = useState(false);
  const [cancelRequestError, setCancelRequestError] = useState(false);

  useEffect(() => {
    // if (!token) return;
    if (!countries) fetchCountries();
  }, [countries]);

  const fetchCountries = async () => {
    try {
      setFetchingCountries(true);
      const countriesData = await getCountries();
      if (countriesData && countriesData.length > 0) {
        setCountries(countriesData);
      } else {
        setCountriesError(true);
      }
      setFetchingCountries(false);
    } catch (e) {
      handleError(e, setCountriesError);
    } finally {
      setFetchingCountries(false);
    }
  };

  const fetchPartners = async (countryCode) => {
    try {
      setPartners([]);
      setPartnersError(false);
      setFetchingPartners(true);
      const partnersData = await getPartners(countryCode);
      if (partnersData && partnersData.length > 0) {
        setPartners(partnersData);
      } else {
        setPartnersError(true);
      }
      setFetchingPartners(false);
    } catch (e) {
      handleError(e, setPartnersError);
    } finally {
      setFetchingPartners(false);
    }
  };

  const checkFormData = async (form) => {
    try {
      setCheckRequestResponse(false);
      setCheckRequestError(false);
      setFetchingCheckRequest(true);

      const checkData = await checkRequest(token, mapRequestBody(form));
      if (checkData) setCheckRequestResponse(checkData);
      else
        setCheckRequestError({
          title: "Ошибка",
          errorMessage: "Нет данных о результате проверки",
        });
      setFetchingCheckRequest(false);
    } catch (e) {
      handleError(e, setCheckRequestError);
    } finally {
      setFetchingCheckRequest(false);
    }
  };

  const sendFormData = async (form) => {
    try {
      setSendRequestResponse(false);
      setSendRequestError(false);
      setFetchingSendRequest(true);

      const responseData = await sendRequest(token, mapRequestBody(form));
      if (responseData) setSendRequestResponse(responseData);
      else
        setSendRequestError({
          title: "Ошибка",
          errorMessage: "Нет данных о результате перевода",
        });
      setFetchingSendRequest(false);
    } catch (e) {
      handleError(e, setSendRequestError);
    } finally {
      setFetchingSendRequest(false);
    }
  };

  const cancelTransaction = async (id) => {
    try {
      setCancelRequestResponse(false);
      setCancelRequestError(false);
      setFetchingCancelRequest(true);

      const cancelResponse = await cancelTransfer(token, id);
      if (cancelResponse) setCancelRequestResponse(cancelResponse);
      else
        setCancelRequestError({
          title: "Ошибка",
          errorMessage: "Нет данных о результате отмены перевода",
        });
      setFetchingCancelRequest(false);
    } catch (e) {
      // console.log(e);
      const {
        name = false,
        description = false,
        error = false,
        errorMessage = false,
        error_message = false,
      } = e.response?.data;

      if (!error_message) {
        setCancelRequestError({
          title:
            name && name === "RIA_ERROR" ? "Ошибка от провайдера" : "Ошибка",
          message: error || description || errorMessage || "Неизвестная ошибка",
        });
      } else {
        setCancelRequestError({
          title: "Ошибка",
          message:
            "В данный момент перевод не может быть отменен. Повторите попытку через несколько минут",
        });
      }
    } finally {
      setFetchingCancelRequest(false);
    }
  };

  const resetRequest = () => {
    setCheckRequestResponse(false);
    setCheckRequestError(false);
    setSendRequestResponse(false);
    setSendRequestError(false);
    setCancelRequestResponse(false);
    setCancelRequestError(false);
  };

  const dropData = () => {
    setCountries(null);
    setFetchingCountries(false);
    setCountriesError(false);

    setPartners(null);
    setFetchingPartners(false);
    setPartnersError(false);

    setFetchingCheckRequest(false);
    setCheckRequestResponse(false);
    setCheckRequestError(false);
  };

  return (
    <RiaContext.Provider
      value={{
        dropData,
        resetRequest,

        // Справочник стран
        countries,
        fetchingCountries,
        countriesError,

        // Справочник партнёров
        fetchPartners,
        partners,
        fetchingPartners,
        partnersError,

        // Чек-запрос
        checkFormData,
        fetchingCheckRequest,
        checkRequestResponse,
        checkRequestError,

        // Отправка перевода
        sendFormData,
        fetchingSendRequest,
        sendRequestResponse,
        sendRequestError,

        // Отмена перевода
        cancelTransaction,
        fetchingCancelRequest,
        cancelRequestResponse,
        cancelRequestError,
      }}
    >
      {children}
    </RiaContext.Provider>
  );
};

export const useRia = () => useContext(RiaContext);
