import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "AuthContext";
import { useUser } from "UserContext";
import { useTransactions } from "./TransactionsContext";

import styles from "./WalletById.module.css";

import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import Wallet from "./Wallet";
import Alert from "components/Alert/Alert";
import Transaction from "./Transaction/Transaction";

const WalletByIdPage = () => {
  const { isAuthenticated, token } = useAuth();
  const { wallets, fetchingWallets } = useUser();
  const {
    fetchTransactions,
    transactions,
    setTransactions,
    fetchingTransactions,
    transactionsError,
  } = useTransactions();

  useEffect(() => {
    setTransactions(null);
    console.info("WalletByIdPage: fetching transactions...");
    fetchTransactions();
    if (transactions) {
      console.info("WalletByIdPage:", transactions.length, "transactions");
    }
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <InnerPageLayout
      title="Переводы"
      showPreloader={fetchingWallets}
      backURL="/wallets"
    >
      <div className={[styles.container, "full-height"].join(" ")}>
        <div className={styles.wallets}>
          {wallets && wallets.length > 0 && (
            <Wallet
              key={wallets[0].id}
              id={wallets[0].id}
              index={wallets[0].id}
              amount={wallets[0].amount}
              currency={wallets[0].currency.symbol}
            />
          )}

          {transactions && transactions.length > 0 && (
            <div className={styles.Transactions}>
              <div className={styles.header}>
                <div className={styles.label}>Международные переводы</div>
                <div className={styles.value}>
                  {transactions ? transactions.length : "..."}
                </div>
              </div>
              {transactions.map((t, i) => (
                <Transaction key={i} transaction={t} />
              ))}
            </div>
          )}
        </div>
      </div>
    </InnerPageLayout>
  );
};

export default WalletByIdPage;
