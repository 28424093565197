// Utils

// 100000 -> 1 000
export const formatAmount = (value) =>
  (value / 100)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    .replace(/\.00$/, "");

// 77051204423 -> +7 (705) 120 44 23
export const formatPhone = (number) => {
  if (typeof number !== "string") number = number.toString();

  if (number.length !== 11 || number[0] !== "7") return "NAPN⛔️";

  return (
    "+7 (" +
    number.substring(1, 4) +
    ") " +
    number.substring(4, 7) +
    " " +
    number.substring(7, 9) +
    " " +
    number.substring(9)
  );
};

// 2023-09-14T06:25:10.963312Z -> 14 сентября в 12:25
export const formatDate = (dateFromDB) => {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const date = new Date(dateFromDB);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return {
    day: `${day} ${month}`,
    time: `${hours}:${minutes}`,
  };
};
