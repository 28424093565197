import axios from "axios";
import { dictionary, debugError, getErrorInfo } from "./errors";
import { BASE_URL } from "./config";
import { events } from "../events";

const api = axios.create({ baseURL: BASE_URL });
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error("API interceptor: authorization error, logging out..");
      events.emit("signOut");
    }
    debugError(error); // Логируем ошибку
    return Promise.reject(error); // Пробрасываем ошибку дальше
  }
);

// API error_code dictionary
export const errors = dictionary;
export const getError = getErrorInfo;
export const wtfError = debugError;

export const getTransactionById = async (token, id) => {
  try {
    const response = await api.get(`/api/v1/transactions?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
};

export const getTransactions = async (token) => {
  try {
    const response = await api.get(`/api/v1/transactions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    wtfError(error);
    throw error;
  }
};
