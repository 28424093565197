import React from "react";
import { Link } from "react-router-dom";
import styles from "./transaction.module.css";

function mapStatus(status) {
  switch (status) {
    case 200:
      return "pending";
    case 400:
      return "error";
    case 600:
      return "success";
    case 450:
      return "pending";
    case 449:
      return "pendingcancel";
    default:
      return "unknown";
  }
}

function statusToText(status) {
  switch (status) {
    case "pending":
      return "Отправлен. Не выдан";
    case "error":
      return "Отменен";
    case "pendingcancel":
      return "Отменяется..";
    case "success":
      return "Выдан получателю";
    default:
      return "Неизвестен";
  }
}

export default ({ transaction = {} }) => {
  const { bene_first_name = "???", bene_last_name = "???" } =
    transaction.metadata?.emsPaymentInfo?.beneficiary?.personal_information;
  const fullName = bene_first_name + " " + bene_last_name;
  const status = mapStatus(transaction.status);
  const statusText = statusToText(status);
  const url =
    "/wallet/" + transaction.payer_wallet + "/transfer/" + transaction.id;
  return (
    <Link className={styles.transaction} to={url}>
      <div className={styles.sideBlock}>
        <div className={styles.icon} state={status}></div>

        <div className={styles.info}>
          <div className={styles.title}>{fullName}</div>
          <div className={styles.subtitle}>{statusText}</div>
        </div>
      </div>

      <div className={styles.sideBlock}>
        <div className={styles.info}>
          <div className={styles.amount} state={status}>
            {transaction.amount}
          </div>
          <div className={styles.date}>{transaction.created_at}</div>
        </div>

        <div className={styles.arrow}></div>
      </div>
    </Link>
  );
};
