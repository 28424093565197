import axios from "axios";
import { debugError } from "./errors";
import { RIA_URL } from "./config";

export const getCountries = async () => {
  try {
    const response = await axios.get(
      `${RIA_URL}/ria-api/v1/reference/countries`,
      {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }
    );
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
};

export const getPartners = async (countryCode) => {
  try {
    const response = await axios.get(
      `${RIA_URL}/ria-api/v1/reference/partners?country_code=${countryCode}`,
      {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }
    );
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
};

export const checkRequest = async (token, data) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios({
      method: "put",
      url: `https://api.ems.test.emoney.tools/api/v1/orders`,
      headers,
      data,
    });
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
};

export const sendRequest = async (token, data) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios({
      method: "post",
      url: `https://api.ems.test.emoney.tools/api/v1/orders`,
      headers,
      data,
    });
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
};

export const cancelTransfer = async (token, id) => {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios({
      method: "delete",
      url: `https://api.ems.test.emoney.tools/api/v1/orders?id=${id}`,
      headers,
    });
    return response.data;
  } catch (error) {
    debugError(error);
    throw error;
  }
};
