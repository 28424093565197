import React from "react";
import InnerPageLayout from "components/InnerPageLayout/InnerPageLayout";
import styles from "components/Form/form.module.css";
import image from "./plug-icon.svg";
import is from "./plug.module.css";

const PlugPage = () => {
  return (
    <InnerPageLayout title="Нет доступа" backURL="/wallets">
      <div className={styles.wrapper}>
        <form className={styles.form} style={{ backgroundImage: `url()` }}>
          <div className={styles.header}>
            <h1>Доступ запрещён</h1>
            <h2>Функция не доступна для тестов на этой учётной записи</h2>
            <img className={is.image} src={image} />
          </div>
        </form>
      </div>
    </InnerPageLayout>
  );
};

export default PlugPage;
